import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaginationInstance } from "ngx-pagination";

interface FeedbackInterface {
	document?: string,
	first_name?: string,
	second_name?: string,
	first_lastname?: string,
	second_lastname?: string,
	username?: string,
	email?: string,
	markEmail?: boolean,
	markUsername?: boolean,
	markDocument?: boolean,
	column?: string,
	row?: number,
	message?: string,
}

@Component({
	selector: 'app-feedback-table',
	templateUrl: './feedback-table.component.html',
	styleUrls: ['./feedback-table.component.scss']
})
export class FeedbackTableComponent implements OnInit {
	columns!: { value: string, field: string, checked: boolean, markField: boolean }[] | any[];
	dataFeedback!: any[];
	hideNotice: boolean = false;
	title!: string;
	subtitle!: string;

	// Control panel para tabla movil
	panelOpenState: boolean = false;

	paginationInstanceData!: PaginationInstance;
	maxSize: number;
	directionLinks: boolean;
	responsive: boolean;
	previousLabel: string;
	nextLabel: string;

	constructor(public dialogRef: MatDialogRef<FeedbackTableComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			result: any,
			columnsFeedbackTable: { value: string, field: string, checked: boolean, markField: boolean }[],
			isFeedbackTemplate: boolean,
			title: string,
			subtitle: string,
		}) {
		this.paginationInstanceData = {
			itemsPerPage: 5,
			currentPage: 1
		};
		this.maxSize = 5;
		this.directionLinks = true;
		this.responsive = false;
		this.previousLabel = '';
		this.nextLabel = '';
	}

	ngOnInit(): void {
		this.buildDataFeedback(this.data.result.error.feedbackData);
		this.columns = this.data.columnsFeedbackTable;
		this.hideNotice = this.data.isFeedbackTemplate;
		this.title = this.data.title;
		this.subtitle = this.data.subtitle;
	}

	buildDataFeedback(data: any[]) {
		if (this.data.isFeedbackTemplate) {
			this.dataFeedback = data.map((el: FeedbackInterface) => {
				return {
					column: el.column,
					row: el.row,
					message: el.message,
					fullName: `Celda ${el.column}${el.row}`
				};
			});
		} else {
			this.dataFeedback = data.map((el: FeedbackInterface) => {
				return {
					document: el.document,
					fullName: `${el.first_name} ${el.second_name || ''} ${el.first_lastname} ${el.second_lastname || ''}`,
					username: el.username,
					email: el.email,
					markEmail: el.markEmail,
					markUsername: el.markUsername,
					markDocument: el.markDocument,
				};
			});
		}
	}

	markField(index: number, field: string): boolean {
		return this.dataFeedback[index][field];
	}

	pageSelected(pageNumber: number) {
		this.paginationInstanceData.currentPage = pageNumber;

	}

}
