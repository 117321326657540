import { NgModule } from '@angular/core';

/** Declarations */
import { AppComponent } from './app.component';

/** Imports */
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatDialogModule } from '@angular/material/dialog';
import { ComponentsModule } from './core/components/components.module';

/** Providers */
import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptor } from './interceptors/auth/auth.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './core/material/material.module';
import { InformationDialog } from './core/components/dialogs/information/information.dialog';
import { ConfirmationDialog } from './core/components/dialogs/confirmation/confirmation.dialog';

@NgModule({
	declarations: [
		AppComponent,

		// Dialogs
		InformationDialog,
		ConfirmationDialog,
	],

	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MaterialModule,
		MatDialogModule,
		ComponentsModule,
		HttpClientModule,
	],

	providers: [
		CookieService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
	],

	bootstrap: [AppComponent],
})
export class AppModule {}
