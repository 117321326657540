<div class="loadFile">
  <div class="title">
    <h3>Cargar archivo</h3>
    <button class="btn btn-transparen button-close" (click)="closeDialog()">
      <span class="visually-hidden">Cerrar ventana</span>
      <img [src]="IMG_CLOSE" alt="close">
    </button>
  </div>
  <form (submit)="onSubmit(); $event.preventDefault();" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
    <div class="body">
      <div *ngIf="!excelFile; else showFile">
        <input type="file" class="input-file" (change)="onFileSelected($event)" accept=".xls,.xlsx,.xlsm">
        <img [src]="IMG_LOAD_FILE" class="image-load" alt="cargar archivo">
      </div>
      <ng-template #showFile>
        <h3 class="loadedFile">Archivo cargado</h3>
        <div class="selectedFile">
          <img [src]="LOGO_EXCEL" alt="">
          <div class="details">
            <div class="filename">
              <p class="detailName">{{excelFile.name}}</p>
              <p class="progress">100%</p>
            </div>
            <div class="barProgress"></div>
          </div>
          <img [src]="CHECK_ICON" alt="">
        </div>
      </ng-template>
    </div>

    <p *ngIf="!excelFile" class="template">{{!loading ? 'Si deseas descargar la plantilla para la carga de ' + typeBulkLoad + ' masivamente' : 'Descargando, espera un momento'}}
      <a class="templateLink" (click)="downloadTemplateFile()" *ngIf="!loading; else spinner"> Descargala aquí </a>
      <ng-template #spinner>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-template>
    </p>
    <div [ngClass]="isSubmiting ? 'hidden' : 'buttons'">
      <app-button [title]="'Cancelar'" [style]="'normal'" (click)="closeDialog()"></app-button>
      <app-button [title]="'Cargar ' + typeBulkLoad" [style]="'primary'" [behavior]="'submit'"></app-button>
    </div>
    <div [class.d-none]="!isSubmiting">
      <div class="my-4">
        <p class="text-center mb-2 text-gray-500 italic">Subiendo plantilla, por favor espere...</p>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </form>
</div>