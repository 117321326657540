import { Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertDangerComponent } from '../alert-danger/alert-danger.component';
import { CrudService } from 'src/app/services/crud.service';

@Component({
	selector: 'app-bulk-load',
	templateUrl: './bulk-load.component.html',
	styleUrls: ['./bulk-load.component.scss']
})
export class BulkLoadComponent implements OnInit {
	IMG_CLOSE!: string;
	IMG_LOAD_FILE!: string;
	LOGO_EXCEL!: string;
	CHECK_ICON!: string;
	excelFile!: File;
	loading: boolean = false;
	private crudService = inject(CrudService);
	isSubmiting!: boolean;
	typeBulkLoad!: string;

	constructor(public dialogRef: MatDialogRef<BulkLoadComponent>,
		private snackBar: MatSnackBar,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.IMG_CLOSE = "/assets/pages/dashboard/svg/dashboard__close__grey-300.svg";
		this.IMG_LOAD_FILE = "/assets/pages/users/users__load_file.svg";
		this.LOGO_EXCEL = "/assets/shared/svg/excel_logo.svg";
		this.CHECK_ICON = "/assets/pages/users/users_step_done.svg";
	}

	ngOnInit(): void {
		this.data.paramToApi === 'students'
			? this.typeBulkLoad = 'estudiantes'
			: this.data.paramToApi === 'users'
				&& (this.typeBulkLoad = 'usuarios');
		
		this.data.secondParam === 'enrolment' ? this.typeBulkLoad = 'matriculas' : null;
	}

	onSubmit() {
		if (!this.excelFile) {
			this.snackBar.openFromComponent(AlertDangerComponent, {
				data: {
					message: "Por favor seleccione un archivo para cargar",
					icon: "check_circle",
				},
				verticalPosition: 'top',
				horizontalPosition: 'center',
				duration: 5000
			});
			return;
		}

		this.isSubmiting = true;
		this.crudService.uploadFile(this.excelFile, this.data.paramToApi, this.data.secondParam).subscribe({
			next: (response: any) => {
				if (response) {
					this.dialogRef.close(response);
				}
			},
			error: error => {
				console.log('Error al cargar archivo ', error);
				this.dialogRef.close(error);
			},
			complete: () => this.isSubmiting = false
		});
	}

	onFileSelected(event: any) {
		this.excelFile = event.target.files[0];
	}

	onDragOver(event: any) {
		event.preventDefault();
	}

	onDrop(event: any) {
		event.preventDefault();
		this.excelFile = event.dataTransfer.files[0];
	}

	closeDialog() {
		this.dialogRef.close();
	}

	downloadTemplateFile() {
		this.loading = true;
		this.crudService.downloadFile(this.data.paramToApi, this.data.secondParam).subscribe({
			next: (fileData: Blob) => {
				this.downloadBlobFile(fileData);
			},
			error: error => {
				console.log('Error al descargar plantilla ', this.data, error);
				this.loading = false;
				this.dialogRef.close();
				this.snackBar.openFromComponent(AlertDangerComponent, {
					data: {
						message: "No es posible descargar la plantilla de momento, por favor intente más tarde",
						icon: "check_circle",
					},
					verticalPosition: 'top',
					horizontalPosition: 'center',
					duration: 5000
				});
			},
			complete: () => this.loading = false
		});
	}

	private downloadBlobFile(data: Blob) {
		const blob = new Blob([data], { type: 'application/octet-stream' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = `CargaMasiva${this.complementFileName(this.data.secondParam || this.data.paramToApi)}.xlsx`;
		link.click();
		window.URL.revokeObjectURL(url);
	}

	private complementFileName(param: string): string {
		param = param.charAt(0).toUpperCase() + param.slice(1);
		switch (param) {
			case 'Users':
				return 'Usuarios';
			case 'Students':
				return 'Estudiantes';
			case 'Enrolment':
				return 'Matriculas';
			default:
				return param;
		}
	}
}
