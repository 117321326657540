import { Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
	selector: 'app-alert-danger',
	templateUrl: './alert-danger.component.html',
	styleUrls: ['./alert-danger.component.scss']
})
export class AlertDangerComponent implements OnInit {
	snackBarRef = inject(MatSnackBarRef);
	closeIcon!: string;
	mainIcon!: string;
	message!: string;
	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { message: string }) {
		this.closeIcon = '/assets/pages/parametrize/close-red.svg';
		this.mainIcon = '/assets/pages/parametrize/info-red.svg';
	}

	ngOnInit(): void {
		switch (this.data.message) {
			// Se crean los mensajes de falla en la creación de los diferentes elementos
			case 'departments fail deleted':
				this.message = "Ha ocurrido en la creación del departamento. \n Por favor intente nuevamente";
				break;

			case 'towns fail deleted':
				this.message = "Ha ocurrido en la creación del municipio. \n Por favor intente nuevamente";
				break;

			case 'institutions fail deleted':
				this.message = "Ha ocurrido en la creación del instituto. \n Por favor intente nuevamente";
				break;

			case 'branches fail deleted':
				this.message = "Ha ocurrido en la creación de la sede. \n Por favor intente nuevamente";
				break;
			
			case 'grades fail deleted':
				this.message = "Ha ocurrido en la creación del ciclo. \n Por favor intente nuevamente";
				break;

			case 'groups fail deleted':
				this.message = "Ha ocurrido en la creación del grupo. \n Por favor intente nuevamente";
				break;

			case 'roles fail deleted':
				this.message = "Ha ocurrido en la creación del rol. \n Por favor intente nuevamente";
				break;
	
			case 'users fail deleted':
				this.message = "Ha ocurrido en la creación del usuario. \n Por favor intente nuevamente";
				break;
			
			case 'students/enrolment fail deleted':
				this.message = "Ha ocurrido en la eliminación de la matricula. \n Por favor intente nuevamente";
				break;

			default:
				this.message = this.data.message;

				break;
		}
	}

}
