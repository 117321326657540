import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {E_ROUTER} from './core/entities/const/router';
import {AuthGuard} from "./guards/auth/auth.guard";

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./core/pages/home/home.module').then( m => m.HomeModule ),
		title: 'Demos vía la vida - Portal'
	},
	{
		path: E_ROUTER.R_LOGIN,
		loadChildren: () => import('./core/pages/authentication/authentication.module').then( m => m.AuthenticationModule ),
		title: 'Demos vía la vida - Autenticación'
	},
	{
		path: E_ROUTER.R_MAIN,
		loadChildren: () => import('./core/layout/layout.module').then(m => m.LayoutModule),
		canActivate: [AuthGuard],
		title: 'Demos vía la vida - Sistema de Información'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
